<template>
  <vx-card :title="title">
    <vs-tabs :value="activeTab">
      <vs-tab label="New">
        <NewTab></NewTab>
      </vs-tab>
      <vs-tab label="Waitting">
        <WaitingTab></WaitingTab>
      </vs-tab>
      <vs-tab label="Approved">
        <ApprovedTab></ApprovedTab>
      </vs-tab>
      <vs-tab label="Closed SOF">
        <ClosedTab></ClosedTab>
      </vs-tab>
      <vs-tab label="Canceled">
        <CanceledTab></CanceledTab>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import NewTab from "./_tab/NewTab.vue";
import WaitingTab from "./_tab/WaittingTab.vue";
import ApprovedTab from "./_tab/ApprovedTab.vue";
import ClosedTab from "./_tab/ClosedTab.vue";
import CanceledTab from "./_tab/CancledTab.vue";

export default {
  components: {
    NewTab,
    WaitingTab,
    ApprovedTab,
    ClosedTab,
    CanceledTab,
  },
  data() {
    return {
      title: "Stock Opname Form",
      activeTab : this.$route.query.tab ? this.$route.query.tab : 0
    }
  }
}
</script>
